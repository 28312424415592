import React, { useEffect, useState } from "react";
import PropTypes from "@arc-fusion/prop-types";
import get from "lodash.get";
import StoryCards from "./StoryCards";
import { useTableLayoutContext } from "~/components/chains/contexts/table-layout-context";
import { useSandwichLayoutContext } from "~/shared-components/layouts/SandwichLayoutContext";
import GridItem from "~/components/layouts/utilities/grid-item.jsx";
import Warning from "~/shared-components/Warning";
import { getFeedLimit } from "../default.helpers.js";
import { wasLinkVisited } from "~/components/layouts/homepage.helpers";

const Feed = (props) => {
  const { id, isAdmin, fetchedContent, overrides, noGrid, editableContentObj } =
    props;
  const { visitedLinks } = useSandwichLayoutContext();
  const [visitedHack, setVisitedHack] = useState({});

  // NOTE: The subsequent code (useEffect, Warning) needs to know if the content has been fetched yet.
  // Before being fetched, the content will be: { isLoading: true }
  const isLoading = get(fetchedContent, "isLoading", false);
  const items = get(fetchedContent, "items", []);

  // NOTE: This is to assure these values are numbers with proper defaults
  let offset = get(overrides, "offset", 0);
  offset = !Number.isNaN(Number.parseInt(offset, 10))
    ? Number.parseInt(offset, 10)
    : 0;

  const limit = getFeedLimit({ items, overrides });

  // NOTE: In the admin, don't let the limit go 0, otherwise the feature
  // won't come back unless the admin is reloaded
  const limitToUse = isAdmin && limit === 0 ? 1 : limit;

  const { updateItemsPerFeatureMapFeedAdjustments, featureId } =
    useTableLayoutContext();

  useEffect(() => {
    // If your feature will render a feed or if you render
    // more than a single item that will use layout variables that
    // feature needs to let the top table know how many items it is rendering.
    // The responsive layout of the top table changes based on the number of items in a
    // column. So if that number is more than one per feature the top table will need to
    // be notified of this.

    // NOTE: Don't update until content has been fetched, i.e. !isLoading
    if (!isLoading && updateItemsPerFeatureMapFeedAdjustments) {
      updateItemsPerFeatureMapFeedAdjustments(featureId, limitToUse);
    }
  }, [
    isLoading,
    updateItemsPerFeatureMapFeedAdjustments,
    featureId,
    limitToUse
  ]);

  useEffect(() => {
    if (!isLoading) {
      // Feeds aren't propertly updating the visited class unless something forces
      // a re-render, putting this value in state forces that re-render
      // The isLoading dependency and if condition ensure that this only runs once
      // when the feed content is loaded and will not incur the infinite loop
      const newVisitedHack = { ...visitedHack };
      items.slice(offset, limit + offset).forEach((item) => {
        if (!newVisitedHack[item.canonical_url]) {
          newVisitedHack[item.canonical_url] = wasLinkVisited(
            visitedLinks,
            item.canonical_url
          );
        }
      });
      setVisitedHack(newVisitedHack);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoading]);

  if (isAdmin && !isLoading && limit === 0) {
    const { msg, level } = fetchedContent
      ? {
          level: "info",
          msg: "This flex feature has no content. It is visible in the admin, but not on the published page. The page/chain/table will adjust to its absence when published -- up to and including complete annihilation of the chain."
        }
      : {
          level: "warning",
          msg: "The feed content backing this feature is invalid or malfunctioning. Fix or remove it."
        };
    return (
      <GridItem
        index={0}
        overrides={overrides}
        featureLabel="The Flex Feature"
        placementRequirements={{ inMultiTableChain: true }}
      >
        <Warning message={msg} level={level} />
      </GridItem>
    );
  }

  return items.slice(offset, limit + offset).map((item, index) => {
    return (
      <StoryCards
        key={index}
        id={id}
        index={index}
        isAdmin={isAdmin}
        fetchedContent={item}
        overrides={overrides}
        noGrid={noGrid}
        editableContentObj={editableContentObj}
        isFeed={true}
        isEditable={false}
        isVisited={visitedHack[item.canonical_url]}
      />
    );
  });
};

Feed.propTypes = {
  id: PropTypes.string,
  fetchedContent: PropTypes.object,
  overrides: PropTypes.object,
  noGrid: PropTypes.bool,
  editableContentObj: PropTypes.object,
  isAdmin: PropTypes.bool
};

export default React.memo(Feed);
