import React from "react";
import PropTypes from "prop-types";

import { PutAShadeOnIt } from "./put-a-shade-on-it";
import { ConditionalWrapper } from "~/shared-components/story-card/_utilities/components";

/**
 * Taking various factors into account, this function determines whether content should be
 * considered a suppressible duplicate or not. First factor is, has this content (judging by
 * renderedContentId) appeared on the page before. Then, has it it been told to suppress duplicates
 * or not.
 *
 * This function also updates the renderedContent object passed in. If this is the Map from the
 * useRenderedContentContext(), then that context will be udpated.
 *
 * @param {renderedContent} object -- For tracking dedupes. In normal use, it should come from
 * useRenderedContentContext(), though this is where it gets updated. The keys in the map are
 * the renderedContentId and the values are an object with a list of featureIds
 * where that content appears.
 * @param {suppressDuplicates} boolean -- In normal use, it should come from useChainContext()
 * @param {renderedContentId} string -- dedup id of content. If ans content, highly recommended to use
 * fusion_additions.links_to_use.basic. It can be any unique identifier.
 * @param {featureId} string -- id of the feature in which the content appears. Recommended to use
 * fingerprint id.
 * @returns {isSuppressibleDuplicate} boolean -- whether this content should be counted as a duplicate.
 * @returns {isDuplicate} boolean -- whether this content is a duplicate due to be suppressed or not.
 */
export const getIsSuppressibleDuplicate = ({
  renderedContent = {},
  suppressDuplicates,
  renderedContentId,
  featureId
}) => {
  let isSuppressibleDuplicate = false;
  let isDuplicate = false;

  if (renderedContentId) {
    renderedContent[renderedContentId] =
      renderedContent[renderedContentId] || {};
    const featureIds = renderedContent[renderedContentId].featureIds || [];
    if (!featureIds.includes(featureId)) featureIds.push(featureId);
    isDuplicate = featureIds.indexOf(featureId) > 0;
    isSuppressibleDuplicate = !!suppressDuplicates && !!isDuplicate;
    if (!isSuppressibleDuplicate) {
      renderedContent[renderedContentId].featureIds = featureIds;
    }
  }
  return { isDuplicate, isSuppressibleDuplicate };
};

export const HandleSuppressibleDuplicateInAdmin = ({
  isAdmin = false,
  isSuppressibleDuplicate = false,
  children
}) => {
  return (
    <ConditionalWrapper
      condition={isAdmin && isSuppressibleDuplicate}
      wrapper={(theseChildren) => (
        <PutAShadeOnIt text="duplicate content" color="orange">
          {theseChildren}
        </PutAShadeOnIt>
      )}
    >
      {children}
    </ConditionalWrapper>
  );
};

HandleSuppressibleDuplicateInAdmin.propTypes = {
  isAdmin: PropTypes.boolean,
  isSuppressibleDuplicate: PropTypes.boolean,
  children: PropTypes.any
};
